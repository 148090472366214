@font-face {
    font-family: Athelas;
    src: url("../assets/fonts/Athelas.ttc"); }

@font-face {
    font-family: Brother;
    font-weight: normal;
    src: url("../assets/fonts/Brother-1816-Light.ttf"); }

@font-face {
    font-family: Brother;
    font-weight: bold;
    src: url("../assets/fonts/Brother-1816-Bold.ttf"); }

$athelas: "Athelas", serif;
$brother: "Brother", sans-serif;

$yellow: #ffe739;
$black: black;
$grey: #b8b8b8;
