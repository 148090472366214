.intro {
    overflow-x: hidden;
    margin-top: -50px;
    position: relative;

    .lemons {
        width: calc(100%);
        min-width: 750px;
        margin-left: 40px;
 }        // max-width: 1200px

    .cta {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 300px;
        text-align: center;

        .cta_1 {
            font-family: $brother;
            font-weight: bold;
            font-size: 32px; }

        .cta_2 {
            font-family: $athelas;
            font-size: 20px;
            margin-top: 40px;
            letter-spacing: 2px; } } }

.shop_lemon {
    text-align: center;
    margin-top: -60px;

    .lemon_shop {
        width: 100px; } }

@media screen and ( max-width: 970px ) {
    .intro {
        margin-top: 30px; }

    .shop_lemon {
        margin-top: -6%; } }

@media screen and ( max-width: 750px ) {
    .intro {
        overflow-x: hidden;
        margin-top: 30px;
        position: relative;
        height: 346.6px;

        .lemons {
            width: calc(100%);
            min-width: 750px;
            margin-left: 40px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%; } }

    .shop_lemon {
        margin-top: -10px; } }
