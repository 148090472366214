.products {
    margin-top: 100px;
    overflow-x: hidden;
    margin-bottom: 60px;

    .text {
        text-align: center;
        font-size: 30px;
        font-family: $brother;
        font-weight: bold;
        letter-spacing: 5px;

        .yellow {
            color: $yellow; }

        .black {
            color: $black; } }

    .group_links {
        margin-top: 80px;
        text-align: center;
        margin-bottom: 80px;

        .link {
            display: inline-block;
            vertical-align: middle;
            margin: 0px 30px;
            width: 150px;
            font-family: $brother;
            font-weight: bold;
            font-size: 20px;
            padding: 0px 10px;
            color: $grey;
            border-bottom: 4px solid $grey;
            letter-spacing: 3px;
            cursor: pointer;

            &.active {
                color: $yellow;
                border-bottom: 4px solid $yellow; } } }

    .group_wrap {
        width: 200%;

        .group {
            text-align: center;
            width: 50%;
            display: none;

            &.active {
                display: block; }

            .flavour {
                text-align: center;
                display: inline-block;
                vertical-align: top;
                margin: 0px 30px;

                .title {
                    font-family: $brother;
                    font-weight: bold;
                    font-size: 22px;
                    margin-top: 60px; }

                .description {
                    margin-top: 20px;
                    font-family: $athelas;
                    font-weight: 600;
                    font-size: 20px;
                    letter-spacing: 4px;
                    width: 300px;
                    display: inline-block; }

                .bundle {
                    margin: 0px 100px; }

                .image {
                    position: relative;

                    .flavour_image {
                        height: 400px;
                        z-index: 1; }

                    .flavour_image_bundle {
                        height: 400px;
                        z-index: -1;
                        position: absolute;

                        &.left {
                            margin-left: -100px;
                            margin-top: -20px; }

                        &.right {
                            margin-left: -51px;
                            margin-top: -20px; } }

                    .flavour_item {
                        position: absolute;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        left: 50%;
                        height: 300px;
                        z-index: -1; }

                    #honeycomb {
                        height: 500px;
                        margin-left: -10px; }

                    #lemon {
                        height: 300px; }

                    #leaf {
                        height: 330px; } } } } } }

@media screen and ( max-width: 500px ) {
    .products {
        .group_wrap {
            .group {
                .flavour {
                    margin-bottom: 80px;

                    .bundle {
                        margin-bottom: -30px; }

                    .image {
                        .flavour_image {
                            height: 300px; }

                        .flavour_image_bundle {
                            height: 300px;

                            &.left {
                                margin-left: -84px;
                                margin-top: -20px; }

                            &.right {
                                margin-left: -27px;
                                margin-top: -20px; } }

                        #honeycomb {
                            height: 400px;
                            margin-left: -10px; }

                        #lemon {
                            height: 220px; }

                        #leaf {
                            height: 250px; } } } } } } }
