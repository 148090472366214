.reviews {
    background-color: $yellow;
    text-align: center;
    padding: 10px 0px 40px;

    .title {
        text-align: center;
        font-size: 30px;
        font-family: $brother;
        font-weight: bold;
        letter-spacing: 5px;
        padding: 20px 0px;
        text-align: center; }

    .stars {
        width: 160px;
        margin-bottom: 20px; }

    .actual_reviews {
        .review {
            display: inline-block;
            width: calc((100% - 10px) / 3 - 60px);
            vertical-align: middle;
            padding: 30px;

            .text {
                text-align: center;
                font-family: $brother;
                font-weight: bold;
                font-size: 22px;
                letter-spacing: 2px; }

            .credit {
                margin-top: 15px;
                font-family: $athelas;
                letter-spacing: 4px; } }

        .divider {
            display: inline-block;
            vertical-align: middle;
            width: 5px;
            background-color: white;
            height: 120px; } } }

@media screen and ( max-width: 900px ) {
    .reviews {
        .actual_reviews {
            .review {
                // display: block
                max-width: calc(100% - 60px);
                width: 500px;
                text-align: center; }

            .divider {
                display: block;
                height: 5px;
                width: 120px;
                margin: 0px calc((100% - 120px) / 2); } } } }

