@import "./components/_variables";

body {
    background-color: #ffffff;
    margin: 0px;

    .App {

        @import "./components/banner/_banner";
        @import "./components/header/_header";
        @import "./components/header/_footer";
        @import "./components/intro/_intro";
        @import "./components/products/_products";
        @import "./components/block/_ocean";
        @import "./components/block/_slick_override";
        @import "./components/reviews/_reviews";
        @import "./components/block/_logo_block";
        @import "./components/mailing/_mailing"; } }
