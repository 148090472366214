.banner {
    padding: 20px 0px;
    font-family: $athelas;
    background-color: $yellow;
    width: 100%;
    overflow-x: hidden;

    .banner_wrap {
        width: calc(400px * 501);
        animation: banner 2000s infinite alternate linear;

        .banner_section {
            display: inline-block;
            vertical-align: middle;

            .callout {
                width: 300px;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                letter-spacing: 2px; }

            .dot {
                width: 100px;
                text-align: center;
                display: inline-block;
                vertical-align: middle; } } } }

@keyframes banner {
    0% {
        transform: translate(0px, 0px); }

    100% {
        transform: translate(-100%, 0px); } }
