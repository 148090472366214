.footer {
    text-align: center;
    overflow: hidden;
    position: relative;
    // margin-top: -37px
    padding-bottom: 100px;

    .logo {
        width: 200px;
        margin-top: -50px; }

    .honey_background, .honey_background_2 {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        min-width: 900px; }

    .honey_background_2 {
        top: 370px;
        transform: rotate(180deg);
        display: none; }

    .section {
        text-align: center;

        .links_section {
            display: inline-block;
            vertical-align: top;
            margin: 0px;
            text-align: center;
            width: calc(100% / 3);
            min-width: 300px;
            margin-top: 160px;

            .links_sub {
                text-align: left;
                display: inline-block;
                // width: 300px

                .links_heading {
                    font-size: 24px;
                    font-family: $brother;
                    font-weight: bold;
                    letter-spacing: 8px; }

                .link {
                    font-size: 20px;
                    font-family: $brother;
                    letter-spacing: 4px;
                    margin: 15px 0px; } } } } }

@media screen and ( max-width: 1050px ) {
    .footer {
        .honey_background_2 {
            display: block; } } }

@media screen and ( max-width: 920px ) {
    .footer {
        .honey_background_2 {
            top: 370px; }

        .section {
            .links_section {
                text-align: left;
                padding-left: 50px;
                margin-top: 30px;
                width: calc(100% - 30px);

                .links_sub {} } } } }
