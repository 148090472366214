.logo_block {
    overflow: hidden;
    height: 400px;
    justify-content: center;
    position: relative;

    .logos {
        width: 120%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    .mints {
        width: 100%; } }

@media screen and ( max-width: 1060px ) {
    .logo_block {
        .mints {
            height: 400px;
            width: auto; } } }

