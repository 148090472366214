.ocean {
    background-color: $yellow;
    text-align: center;
    width: calc(100% + 0px);
    overflow: hidden;

    .prepare_block {
        width: calc(100% / 2 - 30px);
        text-align: center;
        display: inline-block;
        vertical-align: top;
        padding: 45px 15px 15px;
        min-width: 300px;

        .text {
            font-family: $brother;
            font-weight: bold;
            letter-spacing: 5px;
            font-size: 26px;
            line-height: 46px;
            margin-bottom: 60px; }

        .button {
            position: relative;
            text-align: center;

            .buy_now {
                width: 220px;
                display: inline-block; }

            .button_text {
                position: absolute;
                transform: translate(-50%, -50%);
                top: calc(50% - 2px);
                left: 50%;
                font-family: $athelas;
                font-weight: bold;
                font-size: 20px; } } }

    .slideshow_block {
        width: calc(100% / 2 - 10px);
        display: inline-block;
        vertical-align: middle;
        background-color: #ffffff;
        margin: 5px;
        padding-bottom: 80px;
        min-width: 300px;

        .slideshow {
            width: 100%;
            padding: 10px;

            .product {
                text-align: center;
                display: inline-block;

                img {
                    text-align: center;
                    display: inline-block; } } } }

    .logo_ocean {
        width: 125%;
        margin-left: -15%;
        margin-right: -10%;
        margin-bottom: -5px;
        margin-top: -70px;
        min-width: 1000px; } }
