.header {

    .column {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% / 3);

        .logo {
            width: 200px;
            margin: 30px 30px;
            display: inline-block;
            vertical-align: middle; }

        .link {
            display: inline-block;
            vertical-align: middle;
            font-family: $brother;
            font-weight: bold;
            margin: 0px 30px;
            font-size: 22px; }

        .icon {
            display: inline-block;
            vertical-align: middle;
            height: 50px;
            margin: 0px 30px; }

        .burger {
            vertical-align: middle;
            width: 40px; } } }

@media screen and ( max-width: 1150px ) {
    .header {
        .column {
            .logo {
                width: 120px; } } } }

@media screen and ( max-width: 930px ) {
    .header {
        .column {
            .logo {
                width: 80px; }

            .link {
                display: none; }

            .icon {
                height: 40px;
                margin: 0px 10px; } } } }
