.mailing {
    background-color: $yellow;
    text-align: center;
    padding-bottom: 150px;

    .content {
        display: inline-block;
        width: 1020px;
        max-width: 100%;

        .title {
            text-align: center;
            font-family: $brother;
            font-weight: bold;
            font-size: 30px;
            margin-bottom: 15px;
            letter-spacing: 5px;
            padding: 30px 15px 0px; }

        .description {
            padding: 0px 30px 0px;
            text-align: center;
            font-family: $athelas;
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 15px;
            letter-spacing: 5px; }

        .email_box {
            width: calc(100% - 30px);
            background-color: white;
            margin-top: 50px;
            position: relative;
            margin: 50px 15px 0px;

            .btn {
                width: 100px;
                position: absolute;
                transform: translate(-50%, 50%);
                bottom: 0px;
                left: 50%; }

            input {
                border: none;
                border-bottom: 3px solid $black;
                outline: none;
                text-align: center;
                width: calc(100% - 150px);
                margin: 30px 60px 60px;
                padding: 10px;
                font-size: 24px;
                font-family: $brother;
                font-weight: bold;
                letter-spacing: 5px; }

            .invalid, .valid {
                position: absolute;
                top: 27%;
                right: 30px;
                color: red;
                font-size: 40px;
                display: none; }

            .valid {
                color: green; }

            ::-webkit-input-placeholder {
                color: $black; } } } }

@media screen and ( max-width: 600px ) {
    .mailing {
        .content {
            .email_box {
                input {
                    width: calc(100% - 90px);
                    margin: 30px 30px 60px; }

                .invalid, .valid {
                    right: 0px; } } } } }
