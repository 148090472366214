.slick-next:before, .slick-prev:before {
    color: rgba($yellow, 1);
    font-size: 32px; }

.slick-next:hover, .slick-prev:hover {
    border: none;
    outline: none; }

.slick-next {
    right: 10px;
    width: 32px;
    height: 32px; }

.slick-prev {
    left: 10px;
    z-index: 1;
    width: 32px;
    height: 32px; }
